import Swiper, { Controller, Autoplay, Navigation, EffectFlip } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-flip'

document.arrive('.swiper.references', { existing: true }, function(el) {
  const swiper = new Swiper(el, {
    grabCursor: true,
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      768: { slidesPerView: 2 },
      1024: { slidesPerView: 3 },
      1200: { slidesPerView: 3 }
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    speed: 2000,
    navigation: {
      nextEl: ".swiper-references-next",
      prevEl: ".swiper-references-prev",
    },
  })
})

document.arrive('.swiper.blog', { existing: true }, function(el) {
  const swiper = new Swiper(el, {
    grabCursor: true,
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      768: { slidesPerView: 2 },
      1024: { slidesPerView: 3 }
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    speed: 2000,
    navigation: {
      nextEl: ".swiper-blog-next",
      prevEl: ".swiper-blog-prev",
    },
  })
})

document.arrive('.swiper.process-box', { existing: true }, function(el) {
  const swiper = new Swiper(el, {
    modules: [EffectFlip],
    effect: 'flip',
    allowTouchMove: false
  })
})

document.arrive('.swiper.process', { existing: true }, function(el) {
  const swiper = new Swiper(el, {
    grabCursor: true,
    modules: [Controller, Navigation, Autoplay],
    slidesPerView: 1,
    breakpoints: {
      768: { slidesPerView: 2 },
      1024: { slidesPerView: 3 }
    },
    slideToClickedSlide: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    speed: 2000,
    navigation: {
      nextEl: ".swiper-process-next",
      prevEl: ".swiper-process-prev",
    },
    controller: {
      control: document.querySelector('.swiper.process-box').swiper
    }
  })
})
